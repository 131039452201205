import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { take, map, Observable } from 'rxjs';
import { SessionService } from '@services/session.service';

export const loggedInAuthGuard: CanActivateFn = (): Observable<boolean> => {
  const sessionService = inject(SessionService);
  return sessionService.user$.pipe(
    take(1),
    map((user) => {
      if (user) return true;
      inject(Router).createUrlTree(['/error']);
      return false;
    }),
  );
};
