import { environment } from '@environment';
import { getTokenParams } from '@shared/utils/get-token-params';
import { UserInfo } from '@shared/types/user.type';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';

const { token, instance, gameUuid } = getTokenParams();

if (token) {
  fetch(`${environment.backendUrl}/api/public/auth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, instance, gameUuid }),
  })
    .then((response) => response.json())
    .then((data) => {
      (window as Window & typeof globalThis & { user: UserInfo }).user = data;
      platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
    })
    .catch((error) => console.error('Authentication error:', error));
} else {
  console.warn('No token!');
}
